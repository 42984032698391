import Head from 'next/head'
import { Publisher } from 'src/generated/graphql'
import { toHttpsUrl } from '@utils/url'
import { isProprietary } from '@concepts/Publisher/namedPublisher'

const toSchema = (publisher: Publisher) => {
  const url = toHttpsUrl(publisher.hostname as string)

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url,
    name: publisher.platformName,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${url}search?utf8=✓&query={search_term}`,
      'query-input': 'required name=search_term'
    }
  }
}

const WebsiteSchema: React.FC<
  React.PropsWithChildren<{ publisher: Publisher }>
> = ({ publisher }) => {
  if (!isProprietary(publisher.code as string)) return null

  const schema = toSchema(publisher)

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: `${JSON.stringify(schema)}` }}
      />
    </Head>
  )
}

export default WebsiteSchema
