import { Article } from 'src/generated/graphql'
import { ArticleItemType } from '../types/article'
import { formatDateShortMonthDD } from '@utils/dates'

const parse = (article: Article): ArticleItemType => ({
  title: article.headline,
  id: article.databaseId as number,
  date: formatDateShortMonthDD(article.publishedAt),
  imgUrl: article.imageUrl as string,
  slug: `/articles/${article.slug}`
})

export default { parse }
