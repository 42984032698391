import { PageSection } from 'src/generated/graphql'
import Trustbanner from '@molecules/Trustbanner/Trustbanner'
import ArticlePageSection from './ArticlePageSection'
import CollectionPageSection from './CollectionPageSection'
import SalePageSection from './SalePageSection'
import { UIStyles } from '@lib/UIComponents/types'
import { Box } from '@lib/UIComponents'

type PageSectionsProps = {
  pageSections: Array<PageSection>
}

type PageSectionProps = {
  pageSection: PageSection
  pageSectionPosition: number
  sx?: UIStyles
}

const styles = {
  container: {
    marginTop: ['10px', '40px'],
    marginBottom: '40px'
  },
  pageSectionContainer: {
    marginTop: ['20px', '40px']
  },
  collection: {
    p: 3,
    pb: 0
  }
}

const PageSectionComponent = ({
  sx,
  pageSection,
  pageSectionPosition
}: PageSectionProps) => {
  switch (pageSection.__typename) {
    case 'SalePageSection':
      return (
        <SalePageSection
          pageSection={pageSection}
          sx={sx}
          pageSectionPosition={pageSectionPosition}
        />
      )

    case 'CollectionPageSection':
      return (
        <CollectionPageSection
          pageSection={pageSection}
          sx={{ ...sx, ...styles.collection }}
        />
      )

    case 'ArticlePageSection':
      return <ArticlePageSection pageSection={pageSection} sx={sx} />

    case 'TrustbannerPageSection':
      return <Trustbanner />

    default:
      return null
  }
}

const PageSections: React.FC<React.PropsWithChildren<PageSectionsProps>> = ({
  pageSections
}) => {
  return (
    <Box sx={styles.container}>
      {pageSections.map((section, index) => {
        return (
          <PageSectionComponent
            key={Number(section.databaseId)}
            pageSection={section}
            pageSectionPosition={index + 1}
            sx={styles.pageSectionContainer}
          />
        )
      })}
    </Box>
  )
}

export default PageSections
