export const oneYearFromNow = (): Date => {
  const now = Date.now()
  const nextYear = new Date(now).getFullYear() + 1

  return new Date(new Date(now).setFullYear(nextYear))
}

export const getMonthName = (date: Date): string => {
  return date.toLocaleString('default', { month: 'long' })
}

// Month DD, YYYY
export const formatDateMonthDDYYYY = (ISOFormatDate: string): string => {
  const dateObject = new Date(ISOFormatDate)

  return `${getMonthName(
    dateObject
  )} ${dateObject.getDate()}, ${dateObject.getFullYear()}`
}

// Mon DD
export const formatDateShortMonthDD = (ISOFormatDate: string): string => {
  return new Date(ISOFormatDate).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric'
  })
}

// Month YYYY
export const formatDateMonthYear = (ISOFormatDate: string): string => {
  return new Date(ISOFormatDate).toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric'
  })
}

// Mon DD, YYYY
export const formatDateShortMonthDDYYYY = (ISOFormatDate: string): string => {
  return new Date(ISOFormatDate).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  })
}

// YYYY-MM-DD
export const formatDateYYYYMMDD = (ISOFormatDate: string): string => {
  return ISOFormatDate ? ISOFormatDate.split('T')[0] : 'Invalid Date'
}
