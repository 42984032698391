import Container from '@atoms/UIContainer/Container'

type Props = {
  useContainer: boolean
  children: React.ReactNode
}

const Contained = ({ useContainer, children }: Props) => {
  return useContainer ? (
    <Container data-testid="hero-banner-contained" sx={{ mt: [0, 3] }}>
      {children}
    </Container>
  ) : (
    <>{children}</>
  )
}

export default Contained
