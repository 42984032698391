import { Publisher } from 'src/generated/graphql'
import OrganizationSchema from './schemas/Organization'
import WebsiteSchema from './schemas/Website'

export const HomepageSchemaMarkup: React.FC<
  React.PropsWithChildren<{ publisher: Publisher }>
> = ({ publisher }) => (
  <>
    <WebsiteSchema publisher={publisher} />
    <OrganizationSchema publisher={publisher} />
  </>
)
