import {
  TrustCaliforniaIcon as CaliforniaIcon,
  TrustChestIcon as ChestIcon,
  TrustCartIcon as CartIcon
} from '@atoms/Icon/Icon'

import Heading from '@atoms/UIHeading/Heading'
import { Box, Flex, Text } from '@lib/UIComponents'
import Container from '@atoms/UIContainer/Container'
import { UIStyles } from '@lib/UIComponents/types'

const styles = {
  background: {
    padding: ['1rem 0.5rem', '2rem 8rem'],
    border: '1px solid var(--primary-100, #C8C9F3)',
    background: 'var(--primary-25, #F9FAFD)',
    marginTop: '40px'
  },
  container: {
    display: 'flex',
    justifyContent: ['center', 'space-between'],
    alignItem: 'center'
  },
  box: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    flex: '1',
    height: '50%'
  },
  icon: {
    width: ['3.125rem', '7.375rem'],
    height: ['3.125rem', '7.375rem'],
    svg: {
      width: '100%',
      height: '100%'
    }
  },
  title: {
    display: ['none', 'block'],
    fontFamily: 'body',
    textAlign: 'center',
    fontWeight: '700',
    lineHeight: 'normal',
    height: '25%'
  },
  description: {
    width: '100%',
    padding: '0.5rem 0rem',
    textAlign: 'center',
    fontSize: ['xs', 'md'],
    lineHeight: '1.5rem',
    height: '25%'
  }
}

type Props = {
  sx?: UIStyles
}

const Trustbanner = ({ sx }: Props) => {
  return (
    <Box sx={{ ...styles.background, ...sx }}>
      <Container sx={styles.container}>
        <Flex sx={styles.box}>
          <Flex sx={styles.icon}>
            <CartIcon />
          </Flex>

          <Heading sx={styles.title}>Trusted Retailer</Heading>
          <Text sx={styles.description}>Over 5M orders and counting!</Text>
        </Flex>

        <Flex sx={styles.box}>
          <Flex sx={styles.icon}>
            <ChestIcon />
          </Flex>

          <Heading sx={styles.title}>Exclusive Finds</Heading>
          <Text sx={styles.description}>Deals worth talking about!</Text>
        </Flex>

        <Flex sx={styles.box}>
          <Flex sx={styles.icon}>
            <CaliforniaIcon />
          </Flex>

          <Heading sx={styles.title}>Born in California</Heading>
          <Text sx={styles.description}>
            Headquartered in Venice Beach since 2011.
          </Text>
        </Flex>
      </Container>
    </Box>
  )
}

export default Trustbanner
