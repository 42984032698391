import { usePublisherContext } from '@concepts/Publisher/store/context'

type Title = {
  title: string
}

const useHomepageTitle = (): Title => {
  const {
    customTitle,
    proprietary,
    platformName,
    vertical = ''
  } = usePublisherContext()

  const proprietaryTitle = (): string => {
    const subtitle: Record<string, string> = {
      lifestyle: 'Modern & Stylish Goods for Men',
      womens: 'Modern & Stylish Goods for Women',
      academy: 'Online Courses from Top Instructors',
      default: 'The Hottest Tech Deals, Delivered Daily'
    }

    return `${platformName}: ` + (subtitle[vertical] || subtitle.default)
  }

  const getTitle = (): string => {
    if (customTitle) {
      return customTitle
    } else if (proprietary) {
      return proprietaryTitle()
    }

    return String(platformName)
  }

  return { title: getTitle() }
}

export default useHomepageTitle
