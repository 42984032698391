import { isEmpty } from 'ramda'
import {
  A11y,
  Keyboard,
  Mousewheel,
  Pagination,
  Scrollbar
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Path } from '@atoms/UILink/Link'
import Container from '@atoms/UIContainer/Container'
import Heading, { HeadingProps } from '@atoms/UIHeading/Heading'
import { useGlobalContext } from '@config/contexts'

import { UIStyles } from '@lib/UIComponents/types'
import ShowcaseItem, {
  ShowcaseType
} from '@molecules/ShowcaseItem/ShowcaseItem'

import { SaleVariants } from '@molecules/ShowcaseItem/variants'

import { Sale } from 'src/generated/graphql'
import { SaleResponsiveImageKey } from 'src/types/domain'

const styles = {
  title: {
    fontSize: [4, 6],
    px: [2, 3],
    color: 'blacks.0',
    margin: '0 auto'
  },
  swiper: {
    overflow: 'hidden',
    '.swiper': {
      width: ['95%', '100%'],
      height: '100%',
      paddingBottom: 6,
      overflow: ['visible', 'hidden'],
      px: [0, 3],
      mt: 7,
      mb: 2,
      zIndex: 0,
      '&.swiper-initialized': {
        '.swiper-scrollbar': {
          display: 'block'
        }
      }
    },
    '.swiper-wrapper': {
      paddingBottom: 2,
      display: 'flex'
    },
    '.swiper-slide': {
      width: 'auto',
      ':last-child > article': {
        marginRight: 0
      },
      '> article': {
        marginRight: '30px'
      }
    },
    '.swiper-scrollbar': {
      display: 'none',
      width: '100%',
      left: 0,
      bottom: '-15px',
      position: 'absolute',
      background: 'transparent',
      px: 0,
      py: 5,
      overflow: 'hidden',
      transition: 'height 250ms ease !important',
      '::after': {
        content: "''",
        background: 'rgba(0, 0, 0, .1)',
        width: '100%',
        height: '5px',
        position: 'absolute',
        bottom: '20px',
        left: 0,
        borderRadius: '10px',
        zIndex: -1,
        transition: 'height 250ms ease'
      },
      '&:hover': {
        height: '10px',
        '::after': {
          height: '10px'
        }
      }
    },
    '.swiper-scrollbar-drag': {
      cursor: 'grab'
    }
  }
}

type Props = {
  items: Array<Sale>
  imageId: SaleResponsiveImageKey
  title: string
  titleUrl?: Path
  useLink?: boolean
  withAnalytics?: boolean
  onClickItem?: (item: Sale, itemIndex: number) => void
  openModal?: Function
  variant?: SaleVariants
  type?: ShowcaseType
  showWishlistButton?: boolean
  pageSectionPosition?: number
  sx?: UIStyles
} & Pick<HeadingProps, 'as'>

const Showcase = ({
  items,
  imageId,
  titleUrl,
  title,
  as = 'h2',
  useLink = true,
  withAnalytics = false,
  onClickItem,
  openModal,
  variant,
  type = ShowcaseType.SALES,
  showWishlistButton = true,
  sx = {},
  pageSectionPosition
}: Props) => {
  const { page } = useGlobalContext()

  if (isEmpty(items)) return null

  const buildAnalyticsObj = (itemPosition: number) => ({
    analytics: {
      position: itemPosition + 1,
      module: `module ${title}`,
      pageSectionPosition,
      pageSectionTitle: title,
      page
    }
  })

  return (
    <>
      {title && (
        <Container sx={sx}>
          <Heading
            sx={styles.title}
            as={as}
            link={{ external: true, to: titleUrl }}
          >
            {title}
          </Heading>
        </Container>
      )}

      <Container sx={styles.swiper}>
        <Swiper
          modules={[Scrollbar, Keyboard, A11y, Mousewheel, Pagination]}
          spaceBetween={0}
          slidesPerView="auto"
          threshold={10}
          mousewheel={{ forceToAxis: true }}
          keyboard={{ enabled: true }}
          scrollbar={{ draggable: true }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={item.databaseId}>
              <ShowcaseItem
                imageId={imageId}
                item={item}
                useLink={useLink}
                openModal={openModal}
                variant={variant}
                type={type}
                showWishlistButton={showWishlistButton}
                onClickItem={() => {
                  onClickItem && onClickItem(item, index + 1)
                }}
                {...(withAnalytics && buildAnalyticsObj(index))}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </>
  )
}

export default Showcase
