import { isEmpty } from 'ramda'

import { ArticleListType } from '@concepts/Article/types/article'
import InlineCardList from '@molecules/InlineCardList/InlineCardList'

const ArticleList = ({
  articles,
  title,
  titleUrl,
  btnUrl,
  btnTitle,
  analytics,
  columns,
  titleSize,
  size,
  sx
}: ArticleListType) => {
  if (isEmpty(articles)) return null

  return (
    <InlineCardList
      items={articles}
      title={title}
      titleUrl={titleUrl}
      btnUrl={btnUrl}
      btnTitle={btnTitle}
      analytics={analytics}
      columns={columns}
      titleSize={titleSize}
      size={size}
      sx={sx as object}
    />
  )
}

export default ArticleList
