import { useEffect } from 'react'
import { not } from 'ramda'

import { ArticlePageSection as ArticlePageSectionType } from 'src/generated/graphql'

import Container from '@atoms/UIContainer/Container'
import ArticleDTO from '@concepts/Article/dto/ArticleDTO'
import { ArticleItemType } from '@concepts/Article/types/article'
import ArticleCarousel from '@molecules/ArticleCarousel/ArticleCarousel'
import articleAnalytics, { Modules } from '@lib/gtm/events/article'
import ArticleList from '@molecules/ArticleList/ArticleList'
import useMediaQuery from '@hooks/useMediaQuery'
import { UIStyles } from '@lib/UIComponents/types'
import { defaultMediaSizes } from '@lib/UIComponents/Theme/breakpoints'
import { Box } from '@lib/UIComponents'

type ArticleProps = {
  pageSection: ArticlePageSectionType
  sx?: UIStyles
}

const ArticlePageSection = ({ pageSection, sx = {} }: ArticleProps) => {
  const isMobile = useMediaQuery(defaultMediaSizes.ScreenM)
  const analytics = {
    module: pageSection.title as Modules,
    trackClick: articleAnalytics.trackArticleClick
  }

  const articles: Array<ArticleItemType> = pageSection.articles.map(
    ArticleDTO.parse
  )

  useEffect(() => {
    articleAnalytics.trackImpression(articles, analytics.module)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles])

  return (
    <Box sx={sx}>
      {isMobile && (
        <ArticleCarousel
          analytics={analytics}
          articles={articles}
          title={pageSection.title}
        />
      )}

      {not(isMobile) && (
        <Container sx={{ px: 3 }}>
          <ArticleList
            articles={articles}
            analytics={analytics}
            title={pageSection.title}
            columns={pageSection.columns}
          />
        </Container>
      )}
    </Box>
  )
}

export default ArticlePageSection
