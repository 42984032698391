import analytics from '@lib/gtm/analytics'

type BannerAnalytics = {
  homepageHero: (currentUrl: string, destinationUrl: string) => void
}

const bannerAnalytics: BannerAnalytics = {
  homepageHero: (currentUrl, destinationUrl) => {
    analytics.track('Homepage Hero', 'click', {
      eventLabel: 'link',
      currentUrl,
      destinationUrl
    })
  }
}

export default bannerAnalytics
