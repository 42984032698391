import analytics from '@lib/gtm/analytics'
import { ArticleItemType } from '@concepts/Article/types/article'

export type Modules = 'homepage' | 'related' | 'trending'
type ArticleAnalytics = {
  trackImpression: (items: Array<ArticleItemType>, module: Modules) => void
  trackArticleClick: (
    item: ArticleItemType,
    position: number,
    module: Modules
  ) => void
}

type ImpressionArticle = {
  id: string
  headline: string
  position: string
  module: Modules
}

type ArticleEvent = {
  impressions?: Array<ImpressionArticle>
  click?: {
    action: ImpressionArticle
  }
}

type EventAttrs = {
  article: ArticleEvent
}

const track = (action: string, attrs?: EventAttrs): void => {
  analytics.trackSCDataLayer(action, attrs)
}

const parseArticle = (
  item: ArticleItemType,
  position: number,
  module: Modules
): ImpressionArticle => ({
  id: String(item.id),
  headline: item.title,
  position: String(position),
  module
})

const ecommerceAnalytics: ArticleAnalytics = {
  trackImpression: (articles, module) => {
    track('articleImpression', {
      article: {
        impressions: articles.map((article, index: number) =>
          parseArticle(article, index + 1, module)
        )
      }
    })
  },
  trackArticleClick: (article, position, module) => {
    track('articleClick', {
      article: {
        click: {
          action: parseArticle(article, position, module)
        }
      }
    })
  }
}

export default ecommerceAnalytics
