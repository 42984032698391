import Container from '@atoms/UIContainer/Container'
import Heading from '@atoms/UIHeading/Heading'
import Link from '@atoms/UILink/Link'
import Picture from '@atoms/UIPicture/PictureAttributes'
import { ResponsiveImage } from '@atoms/UIPicture/types/picture'

import { Box, Flex } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

import { CollectionPageSection as CollectionPageSectionType } from 'src/generated/graphql'

type ColumnClass = 'column2' | 'column3' | 'column4'

const columnMobileSizes = [
  '0 0 calc(100% - 10px)',
  '0 0 calc(100% - 10px)',
  '0 0 calc(50% - 10px)'
]

const styles = {
  wrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: ['column', 'row'],
    flexWrap: 'wrap',
    img: {
      borderRadius: 'default',
      width: '100%'
    },
    a: {
      marginBottom: 5,
      '&:last-child': {
        marginBottom: [0, 0, 5]
      }
    }
  },
  heading: {
    fontSize: [4, 6],
    marginBottom: 8
  },
  column2: {
    flex: [...columnMobileSizes, '0 0 calc(50% - 10px)']
  },
  column3: {
    flex: [...columnMobileSizes, '0 0 calc(33% - 10px)']
  },
  column4: {
    flex: [...columnMobileSizes, '0 0 calc(25% - 10px)']
  }
}

type CollectionProps = {
  pageSection: CollectionPageSectionType
  sx?: UIStyles
}

const CollectionPageSection = ({ pageSection, sx = {} }: CollectionProps) => {
  const columnClass = styles[`column${pageSection.columns}` as ColumnClass]
  return (
    <Container sx={sx}>
      <Box>
        <Heading sx={styles.heading} as="h2">
          {pageSection.title}
        </Heading>
        <Flex sx={styles.wrapper}>
          {pageSection.pages.map((section, index) => (
            <Link
              to={`/collections/${section.urlSlug}`}
              key={index}
              sx={columnClass}
              external
            >
              <Picture
                alt={section.name}
                responsiveImage={section.mainframeImage as ResponsiveImage}
              />
            </Link>
          ))}
        </Flex>
      </Box>
    </Container>
  )
}

export default CollectionPageSection
