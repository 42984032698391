import { isEmpty, mergeDeepRight } from 'ramda'
import { Swiper, SwiperSlide } from 'swiper/react'

import Heading from '@atoms/UIHeading/Heading'
import Button from '@atoms/UIButton/Button'
import Container from '@atoms/UIContainer/Container'
import Link from '@atoms/UILink/Link'
import { ArticleListType } from '@concepts/Article/types/article'
import { Box } from '@lib/UIComponents'
import InlineCard, { CardSize } from '@molecules/InlineCard/InlineCard'
import { mediaSizesValues } from 'src/components/theme/breakpoints'

const getStyles = (size: CardSize) => {
  const defaultStyles = {
    container: {
      mb: 6,
      px: 3
    },
    swiper: {
      overflow: 'hidden',
      '.swiper': {
        width: ['95%', '100%'],
        maxWidth: [null, ...mediaSizesValues.map((size) => size - 24)],
        height: '100%',
        paddingBottom: '50px',
        overflow: 'visible',
        mb: 2,
        zIndex: 0
      },
      '.swiper-wrapper': {
        paddingBottom: 2,
        display: 'flex'
      },
      '.swiper-slide': {
        width: 'auto'
      },
      '.swiper-scrollbar': {
        width: '100%',
        left: 0,
        bottom: 0,
        background: 'transparent',
        px: 0,
        py: 5,
        overflow: 'hidden',
        transition: 'height 250ms ease !important',
        '::after': {
          content: "''",
          background: 'rgba(0, 0, 0, .1)',
          width: '100%',
          height: '5px',
          position: 'absolute',
          bottom: '20px',
          left: 0,
          borderRadius: '10px',
          zIndex: -1,
          transition: 'height 250ms ease'
        },
        '&:hover': {
          height: '10px',
          '::after': {
            height: '10px'
          }
        }
      },
      '.swiper-scrollbar-drag': {
        cursor: 'grab'
      }
    },
    button: {
      maxWidth: '205px',
      display: 'flex',
      mx: 'auto',
      mt: [4, 7],
      mb: 9
    },
    articleWrapper: {
      width: ['235px', '235px', '264px']
    }
  }

  const mediumStyles = {
    heading: {
      color: 'grays.600',
      mb: 4
    }
  }

  const smallStyles = {
    heading: {
      color: 'grays.600',
      mb: 4
    }
  }

  switch (size) {
    case CardSize.SMALL:
      return mergeDeepRight(defaultStyles, smallStyles)

    case CardSize.MEDIUM:
      return mergeDeepRight(defaultStyles, mediumStyles)

    default:
      return defaultStyles
  }
}

const ArticleCarousel = ({
  articles,
  title,
  titleUrl,
  btnUrl,
  btnTitle,
  analytics,
  size = CardSize.BIG
}: ArticleListType) => {
  if (isEmpty(articles)) return null

  const styles = getStyles(size)

  return (
    <>
      <Container sx={styles.container}>
        {title && (
          <Heading
            as="h2"
            link={{ external: true, to: titleUrl as string }}
            sx={{ fontSize: [5, 5] }}
          >
            {title}
          </Heading>
        )}
      </Container>

      <Box sx={styles.swiper}>
        <Swiper
          threshold={10}
          spaceBetween={30}
          slidesPerView="auto"
          keyboard={{ enabled: true }}
          scrollbar={{ draggable: true }}
          mousewheel={{ forceToAxis: true }}
        >
          {articles.map((item, index) => (
            <SwiperSlide key={item.id}>
              <Box sx={styles.articleWrapper}>
                <InlineCard
                  item={item}
                  analytics={{
                    position: index + 1,
                    module: analytics.module,
                    trackClick: analytics.trackClick
                  }}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {btnTitle && btnUrl && (
        <Button as={Link} variant="outlineDark" to={btnUrl} sx={styles.button}>
          {btnTitle}
        </Button>
      )}
    </>
  )
}

export default ArticleCarousel
