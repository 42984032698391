import { isEmpty, mergeDeepRight } from 'ramda'

import Link from '@atoms/UILink/Link'
import Button from '@atoms/UIButton/Button'
import Heading from '@atoms/UIHeading/Heading'
import InlineCard, {
  CardSize,
  InlineCardItem
} from '@molecules/InlineCard/InlineCard'
import { UIStyles } from '@lib/UIComponents/types'
import { Box } from '@lib/UIComponents'

type InlineCardListAnalytics = {
  module: string | null
  trackClick: Function | null
}

type InlineCardListProps = {
  items: Array<InlineCardItem>
  analytics?: InlineCardListAnalytics
  title?: string
  titleUrl?: string
  size?: CardSize
  titleSize?: CardSize
  btnUrl?: string
  btnTitle?: string
  columns?: number
  sx?: UIStyles
}

const getStyles = (size: CardSize, titleSize: CardSize, columns: number) => {
  const isBig = titleSize === CardSize.BIG
  const isSmall = titleSize === CardSize.SMALL

  const defaultStyles = {
    heading: {
      mb: isBig ? 8 : 4,
      color: isBig ? 'blacks.500' : 'grays.600',
      fontSize: isSmall ? 'lg' : 6,
      fontWeight: isBig ? 'heading' : 'body'
    },
    grid: {
      display: 'grid',
      gridGap: [5, 8, '45px'],
      gridTemplateColumns: [
        `repeat(1, minmax(0, 1fr))`,
        `repeat(2, minmax(0, 1fr))`,
        `repeat(2, minmax(0, 1fr))`,
        `repeat(${columns}, minmax(0, 1fr))`
      ]
    },
    viewAll: {
      maxWidth: '205px',
      display: 'block',
      mx: 'auto',
      mt: [7, 9]
    }
  }

  const smallStyles = {
    grid: {
      gridTemplateColumns: '100%',
      gridGap: 4
    }
  }

  switch (size) {
    case 'small':
      return mergeDeepRight(defaultStyles, smallStyles)

    default:
      return defaultStyles
  }
}

const InlineCardList = ({
  items,
  title,
  titleUrl,
  btnUrl,
  btnTitle,
  analytics,
  columns = 3,
  titleSize = CardSize.BIG,
  size = CardSize.BIG,
  sx = {}
}: InlineCardListProps) => {
  if (isEmpty(items)) return null

  const styles = mergeDeepRight(
    getStyles(size, titleSize, columns),
    sx as object
  )

  return (
    <Box>
      {title && (
        <Heading
          as="h2"
          link={{ external: true, to: titleUrl }}
          sx={styles.heading}
        >
          {title}
        </Heading>
      )}
      <Box sx={styles.grid} data-testid="grid">
        {items.map((item, index) => (
          <InlineCard
            item={item}
            key={item.id}
            size={size}
            analytics={{
              position: index + 1,
              module: analytics?.module as string,
              trackClick: analytics?.trackClick as Function
            }}
          />
        ))}
      </Box>
      {btnTitle && btnUrl && (
        <Button as={Link} variant="outlineDark" to={btnUrl} sx={styles.viewAll}>
          {btnTitle}
        </Button>
      )}
    </Box>
  )
}

export default InlineCardList
