import Head from 'next/head'
import { reject, isEmpty } from 'ramda'
import { Publisher } from 'src/generated/graphql'

import { isProprietary } from '@concepts/Publisher/namedPublisher'
import { toHttpsUrl } from '@utils/url'
import { isInvalid } from '@utils/logic'

const toSchema = (publisher: Publisher) => {
  const url = toHttpsUrl(publisher.hostname as string)

  const sameAs = () => {
    if (!publisher.twitterHandle) return {}

    return {
      sameAs: [`https://twitter.com/${publisher.twitterHandle}`]
    }
  }

  const contactPoint = () => {
    if (!publisher.contactInfo?.phoneNumber) return {}

    return {
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: publisher.contactInfo.phoneNumber,
        contactType: 'Customer Service'
      }
    }
  }

  const address = () => {
    if (!publisher.contactInfo) return {}

    const address = reject(isInvalid, {
      addressCountry: publisher.contactInfo.country,
      postalCode: publisher.contactInfo.zip,
      addressRegion: publisher.contactInfo.state,
      addressLocality: publisher.contactInfo.city
    })

    return !isEmpty(address) ? { address } : {}
  }

  return reject(isInvalid, {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url,
    name: publisher.platformName,
    logo: publisher.logoMain?.url,
    ...sameAs(),
    ...contactPoint(),
    ...address()
  })
}

const OrganizationSchema: React.FC<
  React.PropsWithChildren<{ publisher: Publisher }>
> = ({ publisher }) => {
  if (!isProprietary(publisher.code as string)) return null

  const schema = toSchema(publisher)

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: `${JSON.stringify(schema)}` }}
      />
    </Head>
  )
}

export default OrganizationSchema
