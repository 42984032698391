import { useEffect } from 'react'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { initializeApollo } from '@lib/apollo'
import PageSectionRepository from '@concepts/PageSection/repository/PageSectionRepository'
import { getCurrentTenant } from '@middlewares/tenancy'
import { Publisher } from 'src/generated/graphql'
import Seo from '@concepts/Seo/Seo'
import useHomepageTitle from '@concepts/Seo/hooks/useHomepageTitle'
import PageSections from '@concepts/PageSection/views/PageSections'
import HeroBanner from '@organisms/HeroBanner/HeroBanner'
import Layout from '@templates/Layout/Layout'

import { isClient } from '../utils/env'
import ecommerceAnalytics from '@lib/gtm/events/ecommerce'
import { usePublisherContext } from '../concepts/Publisher/store/context'
import { HomepageSchemaMarkup } from '../concepts/Seo/Schema'
import { withPublisher } from '@middlewares/tenancy/publisher'
import { HomepageProps } from '../types/pages/index'

const Index = ({
  pageSections
}: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element => {
  const { title: customTitle } = useHomepageTitle()
  const publisher = usePublisherContext()

  useEffect(() => {
    if (isClient()) {
      ecommerceAnalytics.trackV2HomePageViewed()
    }
  }, [])

  return (
    <>
      <HomepageSchemaMarkup publisher={publisher as Publisher} />
      <Seo customTitle={customTitle} />

      <Layout useContainer={false}>
        <HeroBanner />

        <PageSections pageSections={pageSections} />
      </Layout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<HomepageProps> = async (
  context
) => {
  // When setting cache on client-side,
  // we must send not only `Cache-Control` but also `X-Cache-Control`,
  // because rails has the setup to set its cache.
  //
  // Remember that all HTTP requests sent here will be cached and
  // things like user, must not be loaded on server side because
  // it will use the cache for other users
  //
  // To disable fetch users in server side, we can pass the option
  // to `withPublisher`.
  const { hostname } = getCurrentTenant(context)
  const apolloClient = initializeApollo({}, { hostname })

  const pageSections = await PageSectionRepository.find(hostname, apolloClient)

  return withPublisher<HomepageProps>(
    context,
    {
      initialApolloState: apolloClient.cache.extract(),
      pageSections,
      page: 'homepage'
    },
    { fetchSSRUser: false }
  )
}

export default Index
